import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [
  {
    path: '/',
    meta: {
      title: '首页-绍兴市工业科学设计研究院有限公司',
    },
    component: () => import('./view/index'),
  },
  {
    path: '/news',
    meta: {
      title: '公司新闻-绍兴市工业科学设计研究院有限公司',
    },
    component: () => import('./view/news'),
  },
  {
    path: '/news/detail/:id',
    meta: {
      title: '公司新闻-绍兴市工业科学设计研究院有限公司',
    },
    component: () => import('./view/news/detail'),
  },
  {
    path: '/contact/us',
    meta: {
      title: '联系我们-绍兴市工业科学设计研究院有限公司',
    },
    component: () => import('./view/contact-us'),
  },

];

// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '');
});

const router = new Router({ routes });

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export {
  router
};
