<template>
  <div id="app">
    <app-header/>
    <div id="body-div">
      <router-view/>
    </div>
    <app-footer/>
  </div>
</template>

<script>
import AppHeader from "@/components/header";
import AppFooter from "@/components/footer";

export default {
  components: {AppFooter, AppHeader}
}
</script>

<style lang="less">
@import "./assets/wangeditor/wangeditor";
body {
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  color: #555555;
  background: #fff;
  font-family: "微软雅黑",serif;
  overflow-x: hidden;
}

#body-div {
  min-height: 500px;
}
</style>
