import request from '../../utils/request'

export default {
  getCase(params) {
    return request({
      url: '/gzhphp/fu_wu_shang/getAnLi',
      type: 'get',
      params: params,
    })
  },

  getNewsDetail(params) {
    return request({
      url: '/gzhphp/wen_zhan/getNewsInfo',
      type: 'get',
      params: params,
    })
  },
  getNewsDetail2(params) {
    return request({
      url: 'http://orderkf.shenmuwl.com/gzhphp/wen_zhan/getNewsInfo',
      type: 'get',
      params: params,
    })
  },
  getWenZhang(params) {
    return request({
      url: '/ke_hu_pc/home/wen_zhang',
      type: 'get',
      params: params,
    })
  },
  getType(params) {
    return request({
      url: '/gongkeyuan/index/get_type',
      type: 'get',
      params: params,
    })
  },
  getTopNav(params) {
    return request({
      url: '/gongkeyuan/index/get_top_nav',
      type: 'get',
      params: params,
    })
  },
  getPublicData(params) {
    return request({
      url: '/ke_hu_pc/home/public_data',
      type: 'get',
      params: params,
    })
  },
}
