import Vue from 'vue';
import App from './App';
import { router } from './router';

// ElementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
// End ElementUI

// 接口
import Apis from "@/api";
Vue.prototype.$api = Apis

// 全局css
// import '../static/public/pc/css/styles.css'

new Vue({
  router,
  el: '#app',
  render: h => h(App)
});
