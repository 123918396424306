<template>
  <div>
    <div class="header">
      <div class="head wid" id="clear">
        <a href="javascript:;" @click="$router.push('/')" class="logo">
          <img src="../../../public/pc/images/gky-logo.png" alt="绍兴市工业科学设计学院">
        </a>
        <ul class="nav">
          <li v-for="(item) in topNav" :key="item.id">
            <a :href="item.href" :target="item.target" :title="item.name">{{ item.name }}</a>
            <dl v-if="item.children && item.children.length > 0">
              <dd v-for="(i) in item.children" :key="i.id">
                <a :href="i.href" :target="i.target" :title="i.name">{{ i.name }}</a>
              </dd>
            </dl>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'app-header',
  data() {
    return {
      topNav: [],
    }
  },
  mounted() {
  },
  created() {
    this.getTopNav()
  },
  methods: {
    getTopNav() {
      this.$api.getTopNav().then(res => {
        this.topNav = res.data
        this.$nextTick(() => {
          $('.nav li').mousemove(function () {
            $(this).find('dl').slideDown()
          });
          $('.nav li').mouseleave(function () {
            $(this).find('dl').stop().slideUp("fast")
          });
        })
      })
    },
  }
}
</script>
