<template>
  <div id="footer-div">
    <div class="footer">
      <div class="footer-link">
        {{ gong_ke_yuan_ban_quan }}
        <a href="http://beian.miit.gov.cn/" target="_blank">{{ gong_ke_yuan_bei_an_hao }}</a
        ><br>
        <a
          target="_blank" :href="'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=' + gong_ke_yuan_gong_an_bei_an"
          style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
          <img src="" style="float:left;">
          <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#FFFFFF;">浙公网安备
            {{ gong_ke_yuan_gong_an_bei_an }}号</p>
        </a>
        <br> 地址：{{ gong_ke_yuan_di_zhi }} 技术支持：
        <a href="http://shenmuwl.com/" target="_blank">神目网络</a>
        <br>
        友情链接：
        <a v-for="item in links" :href="item.href" target="_blank" :key="item.id">{{ item.name }}&nbsp;</a>
      </div>
      <div class="footRight">
        <p class="p1">服务热线</p>
        <p class="p2">{{ gong_ke_yuan_fu_wu_ren_xian }}</p>
        <img :src="gong_ke_yuan_er_wei_ma">
        <p class="p3">微信公众号</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'app-footer',
  data() {
    return {
      gong_ke_yuan_ban_quan: '',
      gong_ke_yuan_bei_an_hao: '',
      gong_ke_yuan_gong_an_bei_an: '',
      gong_ke_yuan_di_zhi: '',
      gong_ke_yuan_fu_wu_ren_xian: '',
      gong_ke_yuan_er_wei_ma: '',
      links: [],
    }
  },
  mounted() {
    this.getPublicData()
    this.getWenZhang()
  },
  methods: {
    getPublicData() {
      this.$api.getPublicData({name: '工科院基本信息'}).then(res => {
        const data = res.data
        this.gong_ke_yuan_ban_quan = data.gong_ke_yuan_ban_quan ? data.gong_ke_yuan_ban_quan.value : ''
        this.gong_ke_yuan_bei_an_hao = data.gong_ke_yuan_bei_an_hao ? data.gong_ke_yuan_bei_an_hao.value : ''
        this.gong_ke_yuan_gong_an_bei_an = data.gong_ke_yuan_gong_an_bei_an ? data.gong_ke_yuan_gong_an_bei_an.value : ''
        this.gong_ke_yuan_di_zhi = data.gong_ke_yuan_di_zhi ? data.gong_ke_yuan_di_zhi.value : ''
        this.gong_ke_yuan_fu_wu_ren_xian = data.gong_ke_yuan_fu_wu_ren_xian ? data.gong_ke_yuan_fu_wu_ren_xian.value : ''
        this.gong_ke_yuan_er_wei_ma = data.gong_ke_yuan_er_wei_ma ? data.gong_ke_yuan_er_wei_ma.value : '../../../public/pc/images/gky-qrcode.jpg'
      })
    },
    getWenZhang() {
      this.$api.getWenZhang({type_id: 35}).then(res => {
        this.links = res.data.data
      })
    }
  }
}
</script>

<style lang="less">
#footer-div {
  display: flex;
  width: 100%;
  padding: 40px 0;
  background: #484848;
  text-align: center;
  color: #FFFFFF;
  a {
    color: #FFFFFF;
  }

  .footer {
    width: 800px;
    margin: 0 auto;

    .footer-link {
      width: 80%;
      float: left;
      margin-top: 50px;
      color: #FFFFFF;
    }
  }
}
</style>
