'use strict'
import axios from 'axios'

// 超时时间5秒
const service = axios.create({timeout: 6000 * 5})
service.defaults.baseURL = '/api'

// 全局拦截请求
service.interceptors.request.use(config => {

  config.headers['X-Requested-With'] = 'xmlhttprequest'
  config.headers['content-type'] = 'application/x-www-form-urlencoded'
  config.headers["Access-Control-Allow-Origin"] = "*"

  return config
}, error => {
  Promise.reject(error)
})
// 全局拦截响应
service.interceptors.response.use(response => {
  const data = response.data
  return data
}, error => {
  return Promise.reject(error)
})


const http = function (obj) {
  const url = obj.url
  const type = obj.type ? obj.type.toUpperCase() : 'GET'

  let params = obj.params || {}
  let headers = obj.headers || {}

  switch (type) {
    case 'GET':
      return service.get(url, {params: params})
    case 'POST':
      return service.post(url, params)
    case 'PUT':
      return service.put(url, params, headers)
    case 'DELETE':
      return service.delete(url, params)
    case 'PATCH':
      return service.patch(url, params, headers)
    default:
  }
}
export default http
